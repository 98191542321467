import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const Nav = () => {
  const { logout } = useAuth0();
  const drawerWidth = 144;

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Drawer open={true} variant='permanent' sx={{ position: 'relative', whiteSpace: 'nowrap', width: drawerWidth }}>
      <List>
        <ListItem>
          <Link to='/'>Dashboard</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link to='/users'>Users</Link>
        </ListItem>
        <ListItem>
          <Link to='/mailings'>Mailings</Link>
        </ListItem>
        <ListItem>
          <Link to='/templates'>Templates</Link>
        </ListItem>
        <ListItem>
          <Link to='/webhooks'>Webhooks</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link to='/dcs/index'>Doc control</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link to='/docmail'>Docmail</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link to='/prices'>Prices</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton onClick={handleLogout}>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Nav;
