import { config } from '../config';
import { useApiGetJson } from '../hooks/api-hook';

export type UserDetails = {
  id: string;
  email: string;
};

const baseUrl = config.Accounts.BaseUri;

export const useGetUserDetails = (userIds: string[]) => {
    const url = userIds.length === 0 ? null : `${baseUrl}users?idList=${userIds.toString()}`;
    return useApiGetJson<UserDetails[]>(url, true);
};
