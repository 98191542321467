import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { PageLoader } from './page-loader';

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const { user, logout } = useAuth0();

  // TODO: need to get this working on auth, not after the page loads
  if (user !== undefined && !user['https://postalzoom.com/roles'].includes('PostalzoomAdmin')) {
    logout({
      returnTo: window.location.origin,
    });
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => {
      return (
        <div className='page-layout'>
          <PageLoader />
        </div>
      );
    },
  });

  return <Component />;
};
function auth0(): { user: any } {
  throw new Error('Function not implemented.');
}
