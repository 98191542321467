import { config } from '../config';
import { useApiGetJson } from '../hooks/api-hook';

export type MailingWrapper = {
  data: Mailing[];
  hasMorePages: boolean;
};

export type Mailing = {
  id: string;
  userId: string;
  name: string;
  createdBy: string;
  status: string;
  created: string;
  lastUpdated: string;
};

export type Prices = {
  letter: number;
  letterColour: number;
  extraSheet: number;
  extraSheetColour: number;
  postcard: number;
  postcardColour: number;
}

const baseUrl = config.Mailings.BaseUri;

export const useGetMailings = (page: number) => {
  return useApiGetJson<MailingWrapper>(`${baseUrl}?page=${page}&qty=10`);
};

export const useGetPrices = () => {
  return useApiGetJson<Prices>(`${baseUrl}prices`);
}