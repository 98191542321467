import { Box } from '@mui/material';
import { Card, Text, Metric, ColGrid, Col } from '@tremor/react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useGetAuth0ActiveUsers, useGetAuth0DailyStats, useGetDocmailBalance, useGetStripeBalance } from '../../clients/admin-api-client';
import { PageLayout } from '../../components/page-layout';
import ErrorBoundary from '../../components/error-boundary';
import { DocmailBalance } from './components/docmail-balance';

export const Dashboard = () => {
  const activeUsers = useGetAuth0ActiveUsers();
  const dailyStats = useGetAuth0DailyStats();
  const stripeBalance = useGetStripeBalance();

  return (
    <PageLayout>
      <Box>
        <ColGrid numCols={6} gapX='gap-x-2' marginTop='mt-2'>
          <Col>
            <ColGrid>
              <Col>
                <Card maxWidth='max-w-sm'>
                  <ErrorBoundary>
                    <DocmailBalance />
                  </ErrorBoundary>
                </Card>
              </Col>
              <Col>
                <Card maxWidth='max-w-sm' marginTop='mt-4'>
                  <Text>Stripe: Available balance</Text>
                  <ErrorBoundary>
                    <Metric>
                      {stripeBalance.loading ? 'loading..' : stripeBalance.error !== null ? 'error' : `£${stripeBalance.data.available}`}
                    </Metric>
                  </ErrorBoundary>
                </Card>
              </Col>
            </ColGrid>
          </Col>
          <Col>
            <Card maxWidth='max-w-sm'>
              <Text>Auth0 active users</Text>{' '}
              <ErrorBoundary>
                <Metric>{activeUsers.loading ? 'loading..' : activeUsers.error !== null ? 'error' : activeUsers.data}</Metric>
              </ErrorBoundary>
            </Card>

            <Card maxWidth='max-w-sm' marginTop='mt-4'>
              <Text>Stripe: Pending balance</Text>{' '}
              <ErrorBoundary>
                <Metric>
                  {stripeBalance.loading ? 'loading..' : stripeBalance.error !== null ? 'error' : `£${stripeBalance.data.pending}`}
                </Metric>
              </ErrorBoundary>
            </Card>
          </Col>
          <Col numColSpan={4}>
            <Card>
              {' '}
              <ErrorBoundary>
                <ResponsiveContainer width='100%' height={300}>
                  <LineChart data={dailyStats.error !== null || dailyStats.loading ? [] : dailyStats.data} margin={{ right: 40 }}>
                    <XAxis dataKey='date' interval={0} />
                    <YAxis />
                    <Line type='monotone' dataKey='logins' stroke='#8884d8' />
                    <Line type='monotone' dataKey='signUps' stroke='#82ca9d' />
                    <Tooltip />
                    <CartesianGrid strokeDasharray='3 3' />
                    <Legend />
                  </LineChart>
                </ResponsiveContainer>
              </ErrorBoundary>
            </Card>
          </Col>
        </ColGrid>
      </Box>
    </PageLayout>
  );
};
