import React from 'react';
import './App.css';
import '@tremor/react/dist/esm/tremor.css';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dashboard } from './pages/dashboard/dashboard-page';
import { ProtectedRoute } from './components/protected-route';
import { PageLoader } from './components/page-loader';
import { CallbackPage } from './pages/callback-page';
import { MailingPage } from './pages/mailings-page';
import { Templates } from './pages/templates-page';
import { Webhooks } from './pages/webhooks-page';
import { Prices } from './pages/prices-page';
import { Users } from './pages/users-page';
import { DocmailPage } from './pages/docmail/docmail-page';
import { DocControlIndexPage } from './pages/doc-control';
import { DocControlTasksPage } from './pages/doc-control/tasks';

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ProtectedRoute component={Dashboard} />} />
        <Route path='/callback' element={<CallbackPage />} />
        <Route path='/mailings' element={<ProtectedRoute component={MailingPage} />} />
        <Route path='/prices' element={<ProtectedRoute component={Prices} />} />
        <Route path='/templates' element={<ProtectedRoute component={Templates} />} />
        <Route path='/webhooks' element={<ProtectedRoute component={Webhooks} />} />
        <Route path='/users' element={<ProtectedRoute component={Users} />} />
        <Route path='/docmail' element={<ProtectedRoute component={DocmailPage} />} />
        <Route path='/dcs/index' element={<ProtectedRoute component={DocControlIndexPage} />} />
        <Route path='/dcs/tasks/:mailingId' element={<ProtectedRoute component={DocControlTasksPage} />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
