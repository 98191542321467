import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { config } from "./config";

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithConfig = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const domain = config.Auth0.Domain;
  const clientId = config.Auth0.ClientId;
  const redirectUri = config.Auth0.RedirectUri;
  const audience = config.Auth0.Audience;

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};