import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Title } from "@tremor/react";
import { PageLayout } from "../../components/page-layout"
import { PageLoader } from "../../components/page-loader";
import { useParams } from "react-router-dom";
import { useGetDocControlSystemMailingTasks } from "../../clients/admin-api-client";

export const DocControlTasksPage = () => {
    const { mailingId } = useParams();
    const tasks = useGetDocControlSystemMailingTasks(mailingId!);

    return (
        <PageLayout>
          <Title>Tasks for mailing {mailingId}</Title>
          {tasks.loading || tasks.status === 0 ? (
            <PageLoader />
          ) : tasks.error !== null ? (
            <p>Error</p>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Task Id</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Address row</TableCell>
                      <TableCell>Zip polling attempts</TableCell>
                      <TableCell>Docmail zip id</TableCell>
                      <TableCell>Docmail failure message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.data.map((task) => (
                      <TableRow key={task.id}>
                        <TableCell>{task.id}</TableCell>
                        <TableCell>{task.mailingTaskType}</TableCell>
                        <TableCell>{new Date(task.created).toLocaleString('en-GB')}</TableCell>
                        <TableCell>{task.row}</TableCell>
                        <TableCell>{task.zipPollAttempts}</TableCell>
                        <TableCell>{task.docmailZipId}</TableCell>
                        <TableCell>{task.docmailFailure}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </PageLayout>
      );
    };
    