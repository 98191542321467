import { Text, Metric } from '@tremor/react';
import { useGetDocmailBalance } from '../../../clients/admin-api-client';

export const DocmailBalance = () => {
    const docmailBalance = useGetDocmailBalance();
    return (
    <>
      <Text>Docmail credit</Text>
      <Metric>{docmailBalance.loading ? 'loading..' : docmailBalance.error !== null ? 'error' : docmailBalance.data}</Metric>
    </>
  );
};
