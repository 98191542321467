import { Title } from "@tremor/react"
import { PageLayout } from "../components/page-layout"

export const Users = () => {

    return (
        <PageLayout>
            <Title>Users</Title>
        </PageLayout>
    )
}