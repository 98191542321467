export const config = {
	Auth0: {
		Domain: process.env.REACT_APP_AUTH0_DOMAIN,
		ClientId: process.env.REACT_APP_AUTH0_CLIENTID,
		RedirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
		Audience: process.env.REACT_APP_AUTH0_AUDIENCE
	},
	Templates: {
		BaseUri: process.env.REACT_APP_TEMPLATES_BASEURI
	},
	Mailings: {
		BaseUri: process.env.REACT_APP_MAILINGS_BASEURI
	},
	Accounts:{
		BaseUri: process.env.REACT_APP_ACCOUNTS_BASEURI
	},
	Wizard: {
		BaseUri: process.env.REACT_APP_WIZARD_BASEURI
	},
	Webhooks: {
		BaseUri: process.env.REACT_APP_WEBHOOKS_BASEURI
	},
	AdminApi: {
		BaseUri: process.env.REACT_APP_ADMINAPI_BASEURI
	},
    PostalzoomUi: {
        BaseUri: process.env.REACT_APP_POSTALZOOMUI_BASEURI
    }
};
