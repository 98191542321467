import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useReducer } from 'react';

export type ApiResponse<Type> = {
  status: Number;
  statusText: string;
  data: Type;
  error: Error | null;
  loading: boolean;
};

const useApi = (
  initialState: ApiResponse<any>,
  url: string | null,
  responseData: (response: Response) => Promise<any>
): ApiResponse<any> => {
  const { getAccessTokenSilently } = useAuth0();

  const [state, dispatch] = useReducer((state: ApiResponse<any>, action: any) => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true };
      case 'loaded':
        return {
          ...initialState,
          loading: false,
          data: action.response.data,
          status: action.response.status,
          statusText: action.response.statusText,
        };
      case 'error':
        return {
          ...initialState,
          loading: false,
          status: action.response.status,
          statusText: action.response.statusText,
          error: action.response.error,
        };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    if (url === null) {
      return;
    }

    const get = async () => {
      var accessToken = await getAccessTokenSilently();
      dispatch({ type: 'loading' });

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const str = await responseData(response);

        dispatch({
          type: 'loaded',
          response: {
            status: response.status,
            statusText: response.statusText,
            data: str,
          },
        });
      } catch (error) {
        dispatch({
          type: 'error',
          response: {
            error: error,
          },
        });
      }
    };

    get();
  }, [getAccessTokenSilently, url, responseData]);

  return state;
};

export const useApiGetString = (url: string | null): ApiResponse<string> => {
  const initialState: ApiResponse<string> = {
    status: 0,
    statusText: '',
    data: '',
    error: null,
    loading: false,
  };

  const extractData = useCallback((response: Response): Promise<any> => {
    return response.text();
  }, []);

  return useApi(initialState, url, extractData);
};

export const useApiGetJson = <Type,>(url: string | null, isArray = false): ApiResponse<Type> => {
  const initialState: ApiResponse<Type | Array<Type>> = {
    status: 0,
    statusText: '',
    data: isArray ? ([] as Type[]) : ({} as Type),
    error: null,
    loading: false,
  };

  const extractData = useCallback((response: Response): Promise<any> => {
    return response.json();
  }, []);

  return useApi(initialState, url, extractData);
};
