import { config } from '../config';
import { useApiGetJson, useApiGetString } from '../hooks/api-hook';

export type Auth0DailyStat = {
  date: string;
  logins: number;
  signUps: number;
};

export type StripeBalance = {
  available: number;
  pending: number;
};

export type DocmailPrices = {
  letter: number;
  letterColour: number;
  extraSheet: number;
  extraSheetColour: number;
  postcard: number;
  postcardColour: number;
};

export type MailingsAwaitingCallback = {
  mailingId: string;
};

export type MailingsCreationFailure = {
    mailingId: string;
    zipId: string;
    attempts: number;
};

export type DcsMailings = {
  id: string;
  name: string;
  mailingFormat: string;
  created: string;
  tasksCompleted: string;
  mailingTaskType: number;
  addressCount: number | null;
  sent: string | null;
}

export type DcsMailingTask = {
  id: number;
  mailingId: string;
  mailingTaskType: string;
  row: number;
  attempt: number;
  zipPollAttempts: number;
  docmailZipId: string;
  docmailFailure: string;
  created: string;
}

const baseUrl = config.AdminApi.BaseUri;

export const useGetDocmailBalance = () => {
  return useApiGetString(`${baseUrl}docmail/balance`);
};

export const useGetAuth0ActiveUsers = () => {
  return useApiGetString(`${baseUrl}auth0/stats/activeusers`);
};

export const useGetAuth0DailyStats = () => {
  return useApiGetJson<Auth0DailyStat[]>(`${baseUrl}auth0/stats/daily`, true);
};

export const useGetStripeBalance = () => {
  return useApiGetJson<StripeBalance>(`${baseUrl}stripe/balance`);
};

export const useGetDocmailPrices = () => {
  return useApiGetJson<DocmailPrices>(`${baseUrl}docmail/prices`);
};

export const useGetAwaitingCallback = () => {
  return useApiGetJson<MailingsAwaitingCallback[]>(`${baseUrl}mailings/awaiting-callback`, true);
};

export const useGetCreationFailures = () => {
    return useApiGetJson<MailingsCreationFailure[]>(`${baseUrl}mailings/creation-failures`, true);
  };
  
export const useGetDocControlSystemMailings = () => {
  return useApiGetJson<DcsMailings[]>(`${baseUrl}doc-control-system/mailings`, true);
}
  
export const useGetDocControlSystemMailingTasks = (mailingId: string) => {
  return useApiGetJson<DcsMailingTask[]>(`${baseUrl}doc-control-system/mailings/${mailingId}/tasks`, true);
}