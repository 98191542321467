import { Col, ColGrid, Title } from '@tremor/react';
import { PageLayout } from '../../components/page-layout';
import { Box } from '@mui/material';
import { CreationFailed } from './components/creation-failed';
import { AwaitingCallback } from './components/awaiting-callback';

export const DocmailPage = () => {
  return (
    <PageLayout>
      <Box>
        <ColGrid numCols={2} gapX='gap-x-2' marginTop='mt-2'>
          <Col>
            <CreationFailed />
          </Col>
          <Col>
            <AwaitingCallback />
          </Col>
        </ColGrid>
      </Box>
    </PageLayout>
  );
};
