import React from 'react';
import Nav from './nav-component';
import { Box, CssBaseline } from '@mui/material';

type LayoutProps = {
  children: React.ReactNode;
};

export const PageLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'auto' }}>
      <CssBaseline />
      <Nav />
      <Box sx={{ flexGrow: 1, overflow: 'auto', padding: '5px' }}>{children}</Box>
    </Box>
  );
};
