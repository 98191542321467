import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useGetDocmailPrices } from '../clients/admin-api-client';
import { useGetPrices } from '../clients/mailing-api-client';
import { PageLayout } from '../components/page-layout';

export const Prices = () => {
  const prices = useGetPrices();
  const docmailPrices = useGetDocmailPrices();

  return (
    <PageLayout>
        <h1>Prices</h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell></TableCell>
              <TableCell>Our price</TableCell>
              <TableCell>Docmail price</TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Letter</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.letter}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.letter}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Letter (Colour)</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.letterColour}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.letterColour}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Extra sheet</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.extraSheet}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.extraSheet}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Extra sheet (Colour)</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.extraSheetColour}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.extraSheetColour}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Postcard</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.postcard}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.postcard}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Postcard (Colour)</TableCell>
                <TableCell>{prices.loading ? 'loading..' : prices.error !== null ? 'error' : prices.data.postcardColour}</TableCell>
                <TableCell>{docmailPrices.loading ? 'loading..' : docmailPrices.error !== null ? 'error' : docmailPrices.data.postcardColour}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
    </PageLayout>
  );
};
