import { Table, TableBody, TableCell, TableHead, TableRow, Title } from '@tremor/react';
import { useGetDocControlSystemMailings } from '../../clients/admin-api-client';
import { PageLayout } from '../../components/page-layout';
import { PageLoader } from '../../components/page-loader';
import { TableContainer } from '@mui/material';
import { Link } from 'react-router-dom';

export const DocControlIndexPage = () => {
  const mailings = useGetDocControlSystemMailings();

  return (
    <PageLayout>
      <Title>Mailings</Title>
      {mailings.loading || mailings.status === 0 ? (
        <PageLoader />
      ) : mailings.error !== null ? (
        <p>Error</p>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Mailing format</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Address count</TableCell>
                  <TableCell>Tasks completed</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell><></></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mailings.data.map((mailing) => (
                  <TableRow key={mailing.id}>
                    <TableCell>{mailing.name}</TableCell>
                    <TableCell>{mailing.mailingFormat}</TableCell>
                    <TableCell>{new Date(mailing.created).toLocaleString('en-GB')}</TableCell>
                    <TableCell>{mailing.mailingTaskType === 0 ? "Single template" : "Multiple templates"}</TableCell>
                    <TableCell>{mailing.addressCount === null ? "~" : mailing.addressCount}</TableCell>
                    <TableCell>{mailing.tasksCompleted === null ? null : new Date(mailing.tasksCompleted).toLocaleString()}</TableCell>
                    <TableCell>{mailing.sent === null ?null : new Date(mailing.sent).toLocaleString()}</TableCell>
                    <TableCell><Link to={`/dcs/tasks/${mailing.id}`}>View tasks</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </PageLayout>
  );
};
