import { Box, Button, Table, TableContainer } from '@mui/material';
import { TableBody, TableCell, TableHead, TableRow, Title } from '@tremor/react';
import { useState } from 'react';
import { useGetTemplateDetails } from '../clients/templates-api-client';
import { PageLayout } from '../components/page-layout';
import { PageLoader } from '../components/page-loader';

export const Templates = () => {
  const [page, setPage] = useState(1);
  const templates = useGetTemplateDetails(page);

  return (
    <PageLayout>
      <Title>Templates</Title>
      {templates.loading || templates.status === 0 ? (
        <PageLoader />
      ) : templates.error !== null ? (
        <p>Error</p>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Page count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.data.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.source}</TableCell>
                    <TableCell>{row.format}</TableCell>
                    <TableCell>{row.pageCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2 }}>
            <Button variant='outlined' disabled={page === 1} onClick={() => setPage(page - 1)}>
              Previous
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant='outlined' onClick={() => setPage(page + 1)}>
              Next
            </Button>
          </Box>
        </>
      )}
    </PageLayout>
  );
};
