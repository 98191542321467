import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Title } from '@tremor/react';
import { useState } from 'react';
import { useGetWebhooks } from '../clients/webhooks-api-client';
import { PageLayout } from '../components/page-layout';
import { PageLoader } from '../components/page-loader';

export const Webhooks = () => {
  const [page, setPage] = useState(1);
  const webhooks = useGetWebhooks(page);

  return (
    <PageLayout>
      <Title>Webhooks</Title>
      {webhooks.loading || webhooks.status === 0 ? (
        <PageLoader />
      ) : webhooks.error !== null ? (
        <p>Error</p>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Url</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {webhooks.data.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{new Date(row.created).toLocaleString('en-GB')}</TableCell>
                    <TableCell>{row.url}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2 }}>
            <Button variant='outlined' disabled={page === 1} onClick={() => setPage(page - 1)}>
              Previous
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant='outlined' onClick={() => setPage(page + 1)}>
              Next
            </Button>
          </Box>
        </>
      )}
    </PageLayout>
  );
};
