import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Title } from '@tremor/react';
import { useGetCreationFailures } from '../../../clients/admin-api-client';
import { PageLoader } from '../../../components/page-loader';

export const CreationFailed = () => {
  const failures = useGetCreationFailures();
  return (
    <>
      <Title>Unable to create with Docmail</Title>
      {failures.loading || failures.status === 0 ? (
        <PageLoader />
      ) : failures.error !== null ? (
        <p>Error</p>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mailing Id</TableCell>
                <TableCell>Zip Id</TableCell>
                <TableCell>Attempts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {failures.data.map((row) => (
                <TableRow key={row.mailingId}>
                  <TableCell>{row.mailingId}</TableCell>
                  <TableCell>{row.zipId}</TableCell>
                  <TableCell>{row.attempts}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
