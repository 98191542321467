import { config } from '../config';
import { useApiGetJson } from '../hooks/api-hook';

const baseUrl = config.Templates.BaseUri;

export type TemplateWrapper = {
  data: TemplateDetail[];
  hasMorePages: boolean;
}

export type TemplateDetail = {
  id: string;
  name: string;
  pageCount: number;
  source: string;
  format: string;
};

export const useGetTemplateDetails = (page: number) => {
  return useApiGetJson<TemplateWrapper>(`${baseUrl}?page=${page}&qty=10`);
};
