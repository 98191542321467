import { config } from '../config';
import { useApiGetJson } from '../hooks/api-hook';

const baseUrl = config.Webhooks.BaseUri;

export type WebhookWrapper = {
  data: Webhook[];
  hasMorePages: boolean;
};

export type Webhook = {
  id: string;
  name: string;
  created: number;
  url: string;
};

export const useGetWebhooks = (page: number) => {
  return useApiGetJson<WebhookWrapper>(`${baseUrl}?page=${page}&qty=10`);
};
