import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Title } from '@tremor/react';
import { useGetAwaitingCallback } from '../../../clients/admin-api-client';
import { PageLoader } from '../../../components/page-loader';

export const AwaitingCallback = () => {
  const awaiting = useGetAwaitingCallback();
  return (
    <>
      <Title>Unable to create with Docmail</Title>
      {awaiting.loading || awaiting.status === 0 ? (
        <PageLoader />
      ) : awaiting.error !== null ? (
        <p>Error</p>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mailing Id</TableCell>
                <TableCell>Zip Id</TableCell>
                <TableCell>Attempts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {awaiting.data.map((row) => (
                <TableRow key={row.mailingId}>
                  <TableCell>{row.mailingId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
